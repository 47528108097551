import mixpanelBrowser from 'mixpanel-browser';

export const mixpanel = mixpanelBrowser;

const MIXPANEL_TOKEN = 'e881183dc84953a0e4c61ddd98548f91';

export const canIUseTracking = !location?.host?.includes('localhost');

export const initTracking = () => {
  mixpanel.init(MIXPANEL_TOKEN);
};

export const trackEvent = (name: string, props: Record<string, any> = null) => {
  if (!canIUseTracking || !mixpanel) {
    console.info('track', JSON.stringify({ name, props }));
    return;
  }

  mixpanel.track(name, props);
};