import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import translationsData from 'src/common/language/translations.json';

export const getSupportedLanguages = () => {
  return [
    { label: 'EN', lang: 'en' },
    { label: 'РУ', lang: 'ru' },
  ];
};

export const getLanguageFromLocale = (locale) => {
  if (locale == null || typeof locale !== 'string') {
    return null;
  }

  return locale.split('-')[0];
};

const DEFAULT_LNG = 'ru';
export const LANGUAGE_STORAGE_KEY = 'lng';

export const initTranslations = () => {
  const supported = getSupportedLanguages().map(it => it.lang);
  const browserLang = getLanguageFromLocale(navigator.language);
  let startLng = localStorage.getItem(LANGUAGE_STORAGE_KEY);

  if (!startLng) {
    startLng = supported.includes(browserLang) ? browserLang : DEFAULT_LNG;
  }

  const resources = Object.entries(translationsData).reduce((res, [key, languages], ind) => {
    const translations = {...res};

    Object.entries(languages).forEach(([lng, text]) => {
      if (translations[lng] == null) {
        translations[lng] = { translation: {} };
      }

      translations[lng].translation[key] = text;
    });


    return translations;
  }, {});

  // const resources = { en: {translation: { key: '' }}, ru: {translation: { key: '' }} }

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: resources,
      lng: startLng, // if you're using a language detector, do not define the lng option
      fallbackLng: 'en',

      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });
};
