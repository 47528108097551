export enum DEVICE_TYPE {
  DESKTOP = 0,
  TABLET,
  MOBILE,
  WIDE_DESKTOP,
}

export const DEVICE_BREAKPOINTS = {
  [DEVICE_TYPE.MOBILE]: [null, 480],
  [DEVICE_TYPE.TABLET]: [481, 1024],
  [DEVICE_TYPE.DESKTOP]: [1025, null],
  [DEVICE_TYPE.WIDE_DESKTOP]: [1200, null],
};