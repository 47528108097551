import React, { Suspense, lazy, useState, useEffect } from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { initTranslations } from 'src/common/language/language.utils';
import { useDevice } from 'src/common/dom/useDevice';
import { MixpanelProvider } from 'react-mixpanel';
import { initTracking, trackEvent, mixpanel } from '../utils/tracking';

const AppRouter = lazy(() => import('src/app/AppRouter'));

initTracking();
initTranslations();

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const initWidget = () => {
  const widgetControls = document.querySelector('.EWBookingWidgetTrigger');

  if (widgetControls != null) {
    widgetControls.addEventListener('click', () => {
      trackEvent('Widget Click');
    });
  }
};

const App = () => {
  const [isReady, setReady] = useState(false);
  const { isDesktop, isTablet, isMobile } = useDevice();

  useEffect(() => {
    trackEvent('App Launch');
  }, [mixpanel]);

  useEffect(() => {
    try {
      setReady(true);

      if (document.readyState === 'interactive' || document.readyState === 'complete') {
        initWidget();
      } else {
        document.addEventListener('DOMContentLoaded', () => {
          initWidget();
        });
      }
    } catch (err) {
      setReady(true);
    }
  }, []);

  return (
    <MixpanelProvider mixpanel={mixpanel}>
      <main
        id="application"
        className={classNames({ invisible: !isReady, desktop: isDesktop, tablet: isTablet, mobile: isMobile })}
      >
        <Suspense fallback={<div></div>}>
          <AppRouter />
        </Suspense>
      </main>
    </MixpanelProvider>
  );
};

export default withTranslation()(App);
